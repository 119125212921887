import IExtension from "./IExtension";
import {Naja} from "naja";

/**
 * Disable automatic zoom when user focus input element for iOS/Safari browser.
 * But also allows user to zoom (in safari, or in other browsers).
 */
export default class DisableAutoZoomInputSafari implements IExtension {

    initialize(naja: Naja): void {
        const metaViewport = <HTMLMetaElement>$('meta[name="viewport"]')[0];
        if (metaViewport) {
            if (this.isIosSafari()) {
                metaViewport.content += ", maximum-scale=1";
            }
        }
    }

    private isIosSafari(): boolean {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        return iOS && webkit && !ua.match(/CriOS/i);
    }

}